import React from "react";

function SvgPottedPlant(props) {
  return (
    <svg height={38} width={26}  {...props}>
      <path
        d="M13.023 8.501v11.006M1.5 23.691h22.766v-4.184H1.5zm18.76 12.806H5.4L2.87 23.692h19.92zM12.152 7.226s-7.866.715-8.866-2.86C2.284.79 8.434.361 12.152 7.225zm2.696.684s5.056-6.067 8.292-4.246c3.236 1.82-.809 6.472-8.292 4.247z"
        fill="none"
        opacity={0.8}
        stroke="#FFF"
        strokeLinecap="round"
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgPottedPlant;
