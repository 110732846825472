import React from "react";
import styled from 'styled-components';

const HalfStarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`
const StarWrapper = styled.div`
  position: relative;
`

function SvgStar({fillColor, strokeColor, ...props}) {
  let upStarColor, downStarColor;
  if (typeof fillColor === 'object') {
    upStarColor = fillColor.left;
    downStarColor = fillColor.right;
  } else {
    downStarColor = fillColor;
    upStarColor =  'none'
  }
  
  return (
    <StarWrapper>
      <HalfStarWrapper>
        <svg height={15} width={8} {...props}>
          <path
            d="M7.992 11.989l-4.697 2.47.897-5.232L.391 5.523l5.253-.764L7.992 0l2.349 4.759 5.253.764-3.801 3.704.897 5.232z"
            fill={upStarColor}
            fillRule="evenodd"
            stroke={strokeColor}
          />
        </svg>
      </HalfStarWrapper>
      <svg height={15} width={16} {...props}>
        <path
          d="M7.992 11.989l-4.697 2.47.897-5.232L.391 5.523l5.253-.764L7.992 0l2.349 4.759 5.253.764-3.801 3.704.897 5.232z"
          fill={downStarColor}
          fillRule="evenodd"
          stroke={strokeColor}
        />
      </svg>
    </StarWrapper>
  );
}

export default SvgStar;
