import React from "react";

function SvgMail(props) {
  return (
    <svg height={30} width={30} {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#000"
        style={{
          mixBlendMode: "multiply"
        }}
      >
        <path d="M3.056 22.778V6.944a.95.95 0 01.972-.972h21.666a.95.95 0 01.973.972v15.834a.95.95 0 01-.973.972H4.028a.95.95 0 01-.972-.972z" />
        <path
          d="M26.667 6.944l-11.25 8.75c-.278.278-.834.278-1.111 0l-11.25-8.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M3.056 22.64l7.777-9.029m15.834 9.029l-7.778-9.029" />
      </g>
    </svg>
  );
}

export default SvgMail;
