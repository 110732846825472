import React from "react";

function SvgStarWhite(props) {
  return (
    <svg height={28} width={30} {...props}>
      <path
        d="M16.351 1.84l3.384 6.856 7.565 1.1c1.235.179 1.73 1.696.834 2.568L22.661 17.7l1.291 7.536c.211 1.23-1.08 2.168-2.185 1.588l-6.766-3.558-6.768 3.558c-1.106.58-2.396-.358-2.185-1.588L7.339 17.7l-5.474-5.336c-.895-.872-.4-2.39.836-2.569l7.566-1.1 3.382-6.855c.553-1.12 2.15-1.12 2.702 0z"
        fill="none"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgStarWhite;
