import React from "react";

function SvgOkComment(props) {
  return (
    <svg height={18} width={13} {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#000"
        style={{
          mixBlendMode: "multiply"
        }}
      >
        <path d="M12.488 16.15V1.417c0-.378-.263-.661-.613-.661H1.614c-.35 0-.614.283-.614.66V16.15c0 .378.263.661.614.661h10.26c.351 0 .614-.283.614-.661z" />
        <path d="M8.367 11.428l1.227 1.605 1.053-2.833" strokeLinecap="round" />
        <path
          d="M3.105 10.2h2.982M3.105 7.367h2.982m-2.982 5.477h2.982"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SvgOkComment;
