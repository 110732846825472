import styled from "styled-components";
import { MEDIA_QUERY } from "../../../constants/style";
import { ClickLoading } from "../../common/ClickLoading";
import Link from "next/link"

const BlogWraper = styled.div`
  outline: none;
  height: 100%;
  
  @media ${MEDIA_QUERY.mobileL} {
    min-height: 225px ;
  }
`

const BlogInnerWrapper = styled.div`
  border: solid 1px #a6a6a6;
  border-radius: 22px;
  background-color: ${props => props.bgColor || "#fff"};
  position: relative;
  text-align: center;
  min-width: 130px;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  box-sizing: border-box;
  height: 100%;
  margin: 0 10px;

  @media ${MEDIA_QUERY.mobileL} {
    padding: 20px 50px;
  }
`

const AnchorWrapper = styled.a`
  color: #fff;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
`

const SvgIconWrapper = styled.div`
  min-height: 40px;
  display: flex ;
  justify-content: center ;
  align-items: center;
`

const ContentWrapper = styled.div`
  margin-top: 19px;
`

const BlogBlock = ({ Icon, content, href, bgColor, marginLeft }) => {
  return (
    <BlogWraper >
      <BlogInnerWrapper bgColor={bgColor} marginLeft={marginLeft} >
        <ClickLoading>
          <Link passHref href={href}>
            <AnchorWrapper>
              <SvgIconWrapper>
                <Icon />
              </SvgIconWrapper>
              <ContentWrapper>
                {content}
              </ContentWrapper>
            </AnchorWrapper>
          </Link>
        </ClickLoading>
      </BlogInnerWrapper>
    </BlogWraper>
  )
}

export default BlogBlock;
