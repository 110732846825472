import React from "react";

function SvgLibra(props) {
  return (
    <svg height={25} width={36} {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        opacity={0.8}
        stroke="#FFF"
        strokeWidth={2}
      >
        <path
          d="M8.763 7.006a16.7 16.7 0 00-.847-1.425 435.4 435.4 0 01-2.852-4.057l-2.841 4.13C1.712 6.333 1 7.764 1 8.68a4.064 4.064 0 008.126 0c0-.598-.11-1.174-.363-1.674zm24.995 0a16.976 16.976 0 00-.846-1.425 440.463 440.463 0 01-2.853-4.057l-2.841 4.13c-.51.679-1.223 2.11-1.223 3.026a4.064 4.064 0 008.127 0c0-.598-.11-1.174-.364-1.674z"
          strokeLinejoin="round"
        />
        <path
          d="M5.064 1.5h24.994M8.212 23.963H26.91m-9.349-22.44v22.439"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}

export default SvgLibra;
