import React from "react";

function SvgPassword(props) {
  return (
    <svg height={30} width={30} {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#040000"
        style={{
          mixBlendMode: "multiply"
        }}
      >
        <path
          d="M4.197 25.167v-13.5c0-.667.5-1.167 1.167-1.167h19.333c.667 0 1.167.5 1.167 1.167v13.5c0 .666-.5 1.166-1.167 1.166H5.364c-.5 0-1.167-.5-1.167-1.166z"
          strokeWidth={0.997}
        />
        <path
          d="M11.364 10.833V6.667c0-2 1.667-3.834 3.833-3.834h0c2 0 3.834 1.667 3.834 3.834v4.166"
          strokeWidth={0.997}
        />
        <g
          fill="#FFF"
          fillRule="nonzero"
          strokeWidth={1.008}
          transform="translate(11.197 12.5)"
        >
          <path d="M3.833 5.5L1 10.5h5.833z" />
          <circle cx={4} cy={4} r={2.667} />
        </g>
      </g>
    </svg>
  );
}

export default SvgPassword;
