import Landing from "../components/layout/Landing"
import { getTranslation } from "../helpers/i18n"
import styled from "styled-components"
import Link from "next/link"
import { CALCULATOR_PATHNAME, COMPLETE_DRY_PATHNAME, COMPLETE_WET_PATHNAME, SEARCH_PRODUCT_PATHNAME } from "../constants/route"
import { ADS_WIDTH, LANDING_WIDTH, MEDIA_QUERY } from "../constants/style"
import { REVALIDATE_SECONDS } from "../constants/nextEnv"
import { FeatureBrands } from "../components/cover/FeatureBrands"
import { getProducts, getProductsByCollectionCount } from "../services/rest/product/server"
import { ClickLoading } from "../components/common/ClickLoading"
import Head from 'next/head' 
import getLiveConfig from "../helpers/getLiveConfig"
import parallel from 'async/parallel';
import shuffleArray from "../helpers/shuffleArray"
import { SORTBY_VIEW_COUNT } from "@constants/api/queryParams"
import SvgMathematicalSymbols from "@components/icons/MathematicalSymbols"
import { CatArticles } from "@components/cover/CatArticles"

const DefaultSectionWrapper = styled.section`
  display: block;
  margin: 20px;
  @media ${MEDIA_QUERY.mobileL} {
    display: flex;
    margin: auto;
    width: ${LANDING_WIDTH}px;
  }
  @media ${MEDIA_QUERY.laptop} {
    margin-left: ${ADS_WIDTH};
  }
`

const FeatureSectionWrapper = styled.section`
  background-color: #d8d8d8;
  display: block;
`

const BlockTitleWrapper = styled.h3`
  bottom: 31px;
  color: #ffffff;
  font-size: 28px;
  margin: 0;
  text-align: center;
  position: absolute;
  width: 100%;
`

const BlockSubTitleWrapper = styled.h4`
  bottom: 16px;
  color: #000;
  font-size:13px;
  margin: 0;
  text-align: center;
  position: absolute;
  width: 100%;
  font-weight: 500;
`

const BlockWrapper = styled.a`
  border-radius: 22px;
  box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.25);
  background-color: #ffaeae;
  background-image: ${(props) => `url(${props.bgImg})`};
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
  cursor: pointer;
  display: block;
  height: 221px;
  max-width: 320px;
  margin: 20px auto;
  position: relative;

  &:hover {
    ${BlockTitleWrapper} { 
      text-decoration: underline; 
    }
  }

  @media ${MEDIA_QUERY.mobileL} {
    flex: 1;
    margin: 0 38px 0 0;
    max-width: 320px;
    height: 221px;
  }
`

export const DefaultSectionTitle = styled.h2`
  color: #3f3f3f;
  font-size: 22px;
  margin-top: 25px;
  padding-left: 20px;
  position: relative;
  &::before {
    content: '';
    background-color: #b9bdc5;
    border-radius: 5px;
    bottom: 0;
    display: inline-block;
    left: 0;
    position: absolute;
    top: 0;
    width: 8px;
  }
  @media ${MEDIA_QUERY.laptop} {
    margin-left: ${ADS_WIDTH};
  }
`

export const SvgIconWrapper = styled.div`
  position: absolute ;
  height: 100%;
  margin: 0 1.2em;
`

export default function Home({
  featureProducts,
  i18n,
  liveConfig,
  productsByCount
}) {
  const [ firstFeature = {}, secondFeature = {}, thirdFeature = {}, fourthFeature  = {}] = liveConfig.featureBlock;
  const blog = liveConfig.blog || [];
  return (
    <Landing
      i18n={i18n}
      liveConfig={liveConfig}
      title={i18n['FIND_CATFOOD_META_TITLE']}
    >
      <Head>
        <meta content={i18n['HOMEPAGE_META_CONTENT']} name='description' />
        <link href="https://purrmaster.com/" rel="canonical" />
      </Head>

      <DefaultSectionTitle>
        {i18n['FIND_CATFOOD']}
      </DefaultSectionTitle>
      <DefaultSectionWrapper>
        <Link href={COMPLETE_WET_PATHNAME}>
          <BlockWrapper bgImg={firstFeature.src}>
            <ClickLoading>
              <BlockTitleWrapper>
                {i18n['ALL_COMPLETE_WET_FOOD']}
              </BlockTitleWrapper>
              <BlockSubTitleWrapper>
                {i18n['BEST_FOOD_FOR_CATS']}
              </BlockSubTitleWrapper>
            </ClickLoading>
          </BlockWrapper>
        </Link>
        <Link href={COMPLETE_DRY_PATHNAME}>
          <BlockWrapper bgImg={secondFeature.src}>
            <ClickLoading>
              <BlockTitleWrapper>
                {i18n['ALL_COMPLETE_DRY_FOOD']}
              </BlockTitleWrapper>
              <BlockSubTitleWrapper>
                {i18n['COMPLEMENTARY_TO_COMPLETEDRY']}
              </BlockSubTitleWrapper>
            </ClickLoading>
          </BlockWrapper>
        </Link>
        <Link href={SEARCH_PRODUCT_PATHNAME}>
          <BlockWrapper bgImg={thirdFeature.src}>
            <ClickLoading>
              <BlockTitleWrapper>
                {i18n['FIND_CATFOOD']}
              </BlockTitleWrapper>
              <BlockSubTitleWrapper>
                {i18n['RECOMMEND_AND_FILTER']}
              </BlockSubTitleWrapper>
            </ClickLoading>
          </BlockWrapper>
        </Link>
        <Link href={CALCULATOR_PATHNAME}>
          <BlockWrapper bgImg={fourthFeature.src}>
            <ClickLoading>
              <SvgIconWrapper>
                <SvgMathematicalSymbols/>
              </SvgIconWrapper>
              <BlockTitleWrapper>
                {i18n['INGREDIENT_CALCULATOR']}
              </BlockTitleWrapper>
            </ClickLoading>
          </BlockWrapper>
        </Link>
      </DefaultSectionWrapper>

      {
        productsByCount.length !== 0 &&
        <FeatureSectionWrapper>
          <FeatureBrands
            hasScore={false}
            i18n={i18n}
            products={productsByCount}
            title={i18n['MOST_COLLECTED']}
          />
        </FeatureSectionWrapper>
      }

      {
        featureProducts.length !== 0 &&
        <FeatureSectionWrapper>
          <FeatureBrands
            i18n={i18n}
            products={featureProducts}
            title={i18n['MOST_VIEWED']}
          />
        </FeatureSectionWrapper>
      }

      {
        blog.length !== 0 &&
        <FeatureSectionWrapper>
          <CatArticles
            blog={blog}
            i18n={i18n}
            title={i18n['RAISING_CAT_KNOWLEDGE_AND_SHARE']}
          />
        </FeatureSectionWrapper>
      }
    </Landing>
  )
}

export async function getStaticProps() {
  const liveConfig = await getLiveConfig();
  const [products, productsByCount] = await parallel([
    function(callback) {
      getProducts({
        attributes: ["uuid", "photo", "name", ['view_count', 'viewCount']],
        includeScore: true,
        includeTotal: false,
        limit: 50,
        sortBy: SORTBY_VIEW_COUNT
      })
      .then(res => {
        if (res.data) {
          const { list = [] } = res.data
          callback(
            null,
            list
          );
        } else {
          callback(
            null,
            []
          );
        }
      }, err => {
        callback(null, []);
      })
    },
    function(callback) {
      getProductsByCollectionCount({
        limit: 50,
        offset: 0,
      })
      .then(res => {
        if (res.data) {
          const { list = [] } = res.data
          shuffleArray(list)
          callback(
            null,
            list
          )
        } else {
          callback(null, [])
        }
      }, err => {
        callback(null, [])
      })
    }
  ])

  return {
    props: {
      ...getTranslation().props,
      featureProducts: products,
      liveConfig,
      productsByCount,
    },
    revalidate: REVALIDATE_SECONDS,
  }
}
