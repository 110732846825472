import React from "react";
import styled from "styled-components";
import { HIGHLIGHT_BG_COLOR } from "../../constants/style";

const SvgPathWrapper = styled.path``

const SvgWrapper = styled.svg`
  &:hover {
    ${SvgPathWrapper} {
      fill: ${HIGHLIGHT_BG_COLOR}
    }
  }
`

function SvgProductReference(props) {
  return (
    <SvgWrapper height={15} width={16} {...props}>
      <g fill="none" fillRule="evenodd" stroke="#000">
        <SvgPathWrapper
          d="M13.425 5.929l-5.1-4.715a.26.26 0 00-.343 0l-5.1 4.715c-.042.042-.042.085-.042.171v7.329c0 .128.128.257.257.257H6.44v-3.429c0-.986.814-1.8 1.8-1.8.985 0 1.8.814 1.8 1.8v3.429h3.257a.277.277 0 00.257-.257V6.057c-.086-.043-.086-.086-.129-.128z"
          fill={props.color ? props.color : "#8A8A8A"}
        />
        <path
          d="M5.454 3.529L.783 7.771M10.725 3.4l4.629 4.243"
          strokeLinecap="round"
        />
      </g>
    </SvgWrapper>
  );
}

export default SvgProductReference;
