import { NAVIGATION_HIGHLIGHT_COLOR } from "@constants/style";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.svg`
  &:hover {
    color: ${NAVIGATION_HIGHLIGHT_COLOR};
    stroke: ${NAVIGATION_HIGHLIGHT_COLOR};
    fill: ${NAVIGATION_HIGHLIGHT_COLOR};
  }
`

function SvgArrowLeft(props) {
  return (
    <Wrapper height={9} width={6} {...props}>
      <path
        d="M4.712 1.233L.878 4.398c-.224.185-.23.479-.016.657l3.85 3.178"
        fill="none"
        stroke={props.stroke || "#231916"}
        strokeLinecap="round"
        strokeWidth={1.2}
      />
    </Wrapper>
  );
}

export default SvgArrowLeft;
