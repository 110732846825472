import React from "react";

function SvgDelete(props) {
  const { style={} } = props;
  return (
    <svg height={16} width={16} {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={style.color || "#000"}
        style={{
          mixBlendMode: "multiply"
        }}
      >
        <path d="M11.429 14.857H5.486c-.915 0-1.6-.686-1.6-1.6V5.03h9.143v8.228c0 1.029-.686 1.6-1.6 1.6z" />
        <path
          d="M6.171 6.857v6.286m2.514-6.286v6.286m2.4-6.286v6.286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.943 5.029H3.086c-.115 0-.343-.115-.343-.343V3.314c0-.114.114-.343.343-.343h10.857c.114 0 .343.115.343.343v1.372c-.115.228-.229.343-.343.343zM9.257 2.971H7.886c-.115 0-.343-.114-.343-.342V1.143c0-.114.228-.343.343-.343h1.371c.114 0 .343.114.343.343v1.371c0 .343-.229.457-.343.457z"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}

export default SvgDelete;
