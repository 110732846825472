import React from "react";

function SvgCan(props) {
  return (
    <svg height={24} width={32} {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        opacity={0.8}
        stroke="#FFF"
        strokeWidth={2}
      >
        <path d="M30.464 7.737c0 3.326-6.595 6.023-14.732 6.023C7.596 13.76 1 11.063 1 7.737c0-3.326 6.596-6.022 14.732-6.022 8.137 0 14.732 2.696 14.732 6.022z" />
        <path d="M30.464 7.737v9.755c0 2.802-6.595 5.073-14.732 5.073C7.596 22.565 1 20.295 1 17.492V7.737" />
      </g>
    </svg>
  );
}

export default SvgCan;
