import SvgTriangle from "./Triangle";
import styled from "styled-components"
import { SCROLL_TO_TOP_COLOR } from "@constants/style";

const SvgTriangleWrapper = styled.div`
  transform: rotate(180deg);
  text-align: center;
`;
const Wrapper = styled.div`
  color: ${props => props.color};
  width: ${props => props.width || '50px' };
  height: ${props => props.height || '50px' };
  border-radius: ${props => props.borderRadius || '50%'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  opacity: 0.7;
`

export default function ToTop({
  color = SCROLL_TO_TOP_COLOR,
  ...props
}){
  return (
    <Wrapper color={color} {...props}>
      <SvgTriangleWrapper>
        <SvgTriangle 
          fill={color}
        />
      </SvgTriangleWrapper>
      Top
    </Wrapper>
  )
}