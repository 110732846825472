import styled from "styled-components";
import { BUTTON_REDIRECT_COLOR, PRODUCT_STARS_BASED_COLOR, PRODUCT_STARS_WIDTH } from "../../../constants/style";
import { ClickLoading } from "../../common/ClickLoading";
import Link from "next/link"
import Stars from "../../stars";
import { getCompanyName } from "./helpers/getCompanyName";
import { PRODUCT_IMAGE_URL } from "../../../constants/imagePath";

const ProductWraper = styled.div`
  outline: none;
`

const ProductInnerWrapper = styled.div`
  border: solid 1px #a6a6a6;
  border-radius: 22px;
  background-color: #fff;
  position: relative;
  text-align: center;
  min-width: 130px;
  padding: 10px 20px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AnchorWrapper = styled.a`
  color: #000000;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
`

const ImageWrapper = styled.img`
  cursor: pointer;
  border-radius: 22px;
  height: 135px;
  margin: auto;
`

const ProductNameWrapper = styled.h4`
  display: -webkit-box;
  font-size: 18px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.4;
  height: 54px;
  overflow: hidden;
`

const CompanyNameWrapper = styled.h5`
  font-size: 14px;
  color: #a1a1a1;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  width: 120px;
  margin: auto;
`


const StarWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CheckoutWrapper = styled.div`
  border: none;
  border-radius: 20px;
  background-color: ${BUTTON_REDIRECT_COLOR};
  color: #fff;
  margin: 15px auto;
  padding: 3px 17px;
  width: 104px;
`;

const ProductSimple = ({ i18n, product, score, hasScore = true }) => {

  return (
    <ProductWraper>
      <ProductInnerWrapper>

        <ClickLoading>
          <Link passHref as={`/product/${product.category}/${product.uuid}`} href='/product/[category]/[productUuid]'>
            <AnchorWrapper>
              <ImageWrapper alt={product.name} loading='lazy' src={`${PRODUCT_IMAGE_URL}/${product.photo}`} />
              {
                product.company &&
                <CompanyNameWrapper>{`${getCompanyName(product.company)}` }</CompanyNameWrapper>
              }
              
              <ProductNameWrapper>{product.name}</ProductNameWrapper>
              {
                hasScore && 
                <StarWrapper>
                  <Stars
                    basedColor={PRODUCT_STARS_BASED_COLOR}
                    score={score}
                    width={PRODUCT_STARS_WIDTH}
                  />
                </StarWrapper>
              }
              <CheckoutWrapper>
                {i18n['CHECKOUT_PRODUCT']}
              </CheckoutWrapper>
            </AnchorWrapper>
          </Link>
        </ClickLoading>
      </ProductInnerWrapper>
    </ProductWraper>
  )
}

export default ProductSimple;
