import React from "react";

function SvgCheckCircle(props) {
  return (
    <svg height={20} width={20} {...props}>
      <g fill="#fff" stroke="#2ca02c" strokeWidth={2.3}>
        <circle cx={10} cy={10} r={8.5} />
        <path d="M5.2 10l3.3 3.4 6.3-6.2" />
      </g>
    </svg>
  );
}

export default SvgCheckCircle;
