import React from "react";

function SvgTableware(props) {
  return (
    <svg height={37} width={21}{...props}>
      <g
        fill="none"
        fillRule="evenodd"
        opacity={0.8}
        stroke="#FFF"
        strokeWidth={2}
      >
        <path d="M19.472 21.163c-2.083 0-3.77-4.514-3.77-10.08C15.701 5.513 17.388 1 19.471 1" />
        <path
          d="M9.36 1v9.672a4.194 4.194 0 01-4.18 4.181A4.193 4.193 0 011 10.672V1m4.18 0v34.016m14.751 0V1"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}

export default SvgTableware;
