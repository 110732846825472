import Slider from "react-slick";
import styled from "styled-components";
import { MEDIA_QUERY, TOUCH_THRESDHOLD } from "../../constants/style";
import Dot from "../style/Dot";
import NextButtonWrapper from "./style/NextButtonWrapper";
import PrevButtonWrapper from "./style/PrevButtonWrapper";
import ProductSimple from "./features/ProductSimple";
import { useRef } from "react";

const MAX_DOTS_COUNT = 5

const RootWrapper = styled.div`
  margin-top: 46px;
  padding: 64px 0;
`
const DotWraper = styled.div`
  position: absolute;
  bottom: -40px;
  @media ${MEDIA_QUERY.mobileL} {
    display: none;
  }
`

const UlWrapper = styled.ul`
  padding: 0;
`

const TitleWrapper = styled.h2`
  color: #111111;
  font-size: 22px;
  margin: 20px;
`

function NextArrow(props) {
  const { onClick } = props;
  if (onClick === null) { return null }
  return (
    <NextButtonWrapper className='feature-next' onClick={onClick} />
  );
}

function PrevArrow(props) {
  const { onClick, currentSlide } = props;
  if (currentSlide === 0) { return null }
  return (
    <PrevButtonWrapper className='feature-prev' onClick={onClick} />
  );
}

export const FeatureBrands = ({ i18n, products, hasScore, title }) => {
  const refIndex = useRef({end: MAX_DOTS_COUNT - 1, start: 0})
  const settings = {
    appendDots: (dots) => {  
      const activeIndex = dots.findIndex((dot)=> dot.props.className === 'slick-active')

      if (activeIndex + 1 < dots.length && activeIndex + 1 > refIndex.current.end) {
        refIndex.current.end++;
        refIndex.current.start++;
      }

      if (activeIndex - 1 >= 0 && activeIndex - 1 < refIndex.current.start) {
        refIndex.current.start--;
        refIndex.current.end--;
      }

      return (
        <DotWraper className='carousel-dot-wrapper'>
          <UlWrapper>{dots.slice(refIndex.current.start, refIndex.current.end + 1)}</UlWrapper>
        </DotWraper>
      )
    },
    customPaging: () => <Dot bgColor='#a8a8a8' />,
    infinite: false,
    initialSlide: 0,
    lazyLoad: 'anticipated',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 525,
        settings: {
          dots: true,
          slidesToScroll: 2,
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 725,
        settings: {
          dots: true,
          slidesToScroll: 3,
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 880,
        settings: {
          dots: true,
          slidesToScroll: 3,
          slidesToShow: 3,
        }
      }
    ],
    slidesToScroll: 5,
    slidesToShow: 5,
    speed: 500,
    touchThreshold: TOUCH_THRESDHOLD,
  }
  return (
    <RootWrapper className='feature-brand'>
      <TitleWrapper>
        {title}
      </TitleWrapper>
      <Slider {...settings}>
        {
          products.map((product, idx) => {
            return (
              <ProductSimple
                key={idx}
                hasScore={hasScore}
                i18n={i18n}
                product={product}
                score={product.reviewScore}
              />
            )
          })
        }
      </Slider>
    </RootWrapper>
  )
}
