import React from "react";

function SvgEdit(props) {
  return (
    <svg height={props.height || 15}  viewBox="0 0 19 15" {...props}>
      <g fill={props.fill || 'none'} fillRule="evenodd" stroke={props.stroke || '#000'}>
        <path d="M10.6 13.6V1.3c0-.3-.2-.6-.6-.6H1.6c-.4 0-.6.2-.6.6v12.3c0 .3.2.6.6.6H10c.3 0 .6-.4.6-.6z" />
        <path
          d="M2.9 8.5h2.7M2.9 6.2h2.7m-2.7 4.6h2.7m10.1-.3l-2-.5 2.2-8.1c0-.2.2-.2.4-.2l1.5.4c.1 0 .2.2.2.3l-2.3 8.1z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.6 10l.5 2.3 1.6-1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SvgEdit;
