import React from "react";

function SvgMathematicalSymbols(props) {
  return (
    <svg height="100%" viewBox="0 0 310 54" width="100%" {...props}>
      <defs>
        <filter
          filterUnits="objectBoundingBox"
          height="108.9%"
          id="MathematicalSymbols_svg__a"
          width="106%"
          x="-3%"
          y="-4.4%"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation={2}
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        filter="url(#MathematicalSymbols_svg__a)"
        opacity={0.9}
        transform="translate(-45.5 -97)"
      >
        <path
          d="M71.843 138.651v-28.689M57.5 124.303h28.689m108.593 8.263l20.286-20.286m-20.286 0l20.286 20.286"
          stroke="#FFF"
          strokeLinecap="round"
          strokeWidth={5}
        />
        <path
          d="M273.317 137.408a3.866 3.866 0 01-3.864 3.864 3.865 3.865 0 010-7.73 3.863 3.863 0 013.864 3.866m0-25.551a3.863 3.863 0 11-7.725 0 3.868 3.868 0 013.86-3.864 3.866 3.866 0 013.865 3.864"
          fill="#FFF"
        />
        <path
          d="M255.578 125.1h28.69"
          stroke="#FFF"
          strokeLinecap="round"
          strokeWidth={5}
        />
        <path
          d="M126.296 121.854h28.689m169.39 6h19m-19-11h19"
          fill="#FFF"
          stroke="#FFF"
          strokeLinecap="round"
          strokeWidth={5}
        />
      </g>
    </svg>
  );
}

export default SvgMathematicalSymbols;
