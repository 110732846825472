import React from "react";
import { PRODUCT_IMG_HEIGHT, PRODUCT_IMG_WIDTH } from "../../constants/style";

export default function SvgImgPlaceholder(props) {
  const {text} = props;
  return (
    <svg height={`${PRODUCT_IMG_HEIGHT}`} viewBox={`0 0 ${PRODUCT_IMG_WIDTH} ${PRODUCT_IMG_HEIGHT}`} width={`${PRODUCT_IMG_WIDTH}`} xmlns="http://www.w3.org/2000/svg">
      <rect fill="#ddd" height={`${PRODUCT_IMG_HEIGHT}`} width={`${PRODUCT_IMG_WIDTH}`}/>
      <text dy="10.5" fill="rgba(0,0,0,0.5)" font-family="sans-serif" font-size="18" font-weight="bold" text-anchor="middle" x="50%" y="50%">{text}</text>
    </svg>
  )
}
