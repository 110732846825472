
import SvgStar from "../icons/Star";
import styled from "styled-components";
import { STAR_SCORE_COLOR } from "../../constants/style";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${props => props.width};
`;
const SvgStarWrapper = styled.div`
  cursor: ${props => props.pointer? 'pointer': ''};
`;

const TOTAL_START_COUNT = 5; 

function trunc(num) {
  if(num >= 0) return Math.floor(num);
  return Math.ceil(num);
}

function getStars({
  IconComponent,
  count,
  fillColor,
  keyPrefix,
  onChange,
  onClick,
  offset = 0,
  score,
  strokeColor,
  pointer
}){
  const ret = [];

  for(let i = 0; i < count; i++ ){
    ret.push(
      <SvgStarWrapper
        key={`${keyPrefix}-${i}`}
        onClick={()=> {
          const clickValue = i + 1 + offset
          if (clickValue === score) {
            onChange(clickValue - 1);
          } else {
            onChange(clickValue);
          }
          onClick(clickValue);
        }}
        pointer={pointer}
      >
        <IconComponent
          fillColor={fillColor}
          strokeColor={strokeColor? strokeColor : ''}
        />
      </SvgStarWrapper>
    );
  }
  return ret;
}

export default function Stars({
  basedColor,
  IconComponent = SvgStar,
  onChange,
  pointer,
  score,
  strokeColor,
  width,
  onClick
}){
  if (!score) score = 0;
  score = score > TOTAL_START_COUNT ? TOTAL_START_COUNT : score;
  
  const scoreColorCount = trunc(score);
  const halfColorCount = (score - scoreColorCount) >= 0.45 ? 1 : 0;
  const baseColorCount = TOTAL_START_COUNT - scoreColorCount - halfColorCount;

  return (
    <Wrapper width={width}>
      {
        [
          ...getStars({
            IconComponent,
            count: scoreColorCount,
            fillColor: STAR_SCORE_COLOR,
            keyPrefix: 'socre',
            onChange,
            onClick,
            pointer,
            score,
            strokeColor,
          }),
          ...getStars({
            IconComponent,
            count: halfColorCount,
            fillColor: {
              left: STAR_SCORE_COLOR,
              right: basedColor
            },
            keyPrefix: 'half',
            offset: scoreColorCount,
            onChange,
            onClick,
            pointer,
            score,
            strokeColor,
          }),
          ...getStars({
            IconComponent,
            count: baseColorCount,
            fillColor: basedColor,
            keyPrefix: 'none',
            offset: scoreColorCount + halfColorCount,
            onChange,
            onClick,
            pointer,
            score,
            strokeColor,
          })
        ]
      }
    </Wrapper>
  )
}
const noopFunc = ()=>{}
Stars.defaultProps = {
  onChange: noopFunc,
  onClick: noopFunc,
}
