import React from "react";

function SvgSearch(props) {
  return (
    <svg height={13} width={13} {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        style={{
          mixBlendMode: "multiply"
        }}
        transform="translate(.25 .25)"
      >
        <path d="M11.75 12.083L7.417 7.75" />
        <circle cx={4.583} cy={4.5} r={4.167} />
      </g>
    </svg>
  );
}

export default SvgSearch;
