export function getCompanyName(companyObj) {
  let companyName = ''
  if (companyObj.name_en) {
    companyName = `${companyName}${companyObj.name_en}`
  }
  if (companyObj.name_ch) {
    companyName = `${companyName}${companyObj.name_ch}`
  }

  return companyName
}