import React from "react";

function SvgMore(props) {
  return (
    <svg height={14} width={4} {...props}>
      <g fillRule="evenodd" transform="translate(.583 .083)">
        <circle cx={1.25} cy={1.25} r={1.25} />
        <circle cx={1.25} cy={6.25} r={1.25} />
        <circle cx={1.25} cy={11.25} r={1.25} />
      </g>
    </svg>
  );
}

export default SvgMore;
