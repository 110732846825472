import React from "react";

function SvgChevronRight(props) {
  return (
    <svg
      className="chevron-right_svg__ipc-icon chevron-right_svg__ipc-icon--chevron-right-inline chevron-right_svg__ipc-icon--inline chevron-right_svg__ipc-title-link-chevron"
      fill="currentColor"
      height={24}
      width={24}
      {...props}
    >
      <path d="M5.622.631A2.153 2.153 0 005 2.147c0 .568.224 1.113.622 1.515l8.249 8.34-8.25 8.34a2.16 2.16 0 00-.548 2.07c.196.74.768 1.317 1.499 1.515a2.104 2.104 0 002.048-.555l9.758-9.866a2.153 2.153 0 000-3.03L8.62.61c-.808-.817-2.17-.817-2.998.02z" />
    </svg>
  );
}

export default SvgChevronRight;
